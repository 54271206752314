:root {
  --color-primary: #b48486;
  --color-secondary: #e8d9d9;
  --color-primary-dark: #5b3739;
}

font-face {
  src: url("../AcuminPro-BoldItalic.23c5c952.eot");
  src: url("../AcuminPro-BoldItalic.23c5c952.eot#iefix") format("embedded-opentype"), url("../AcuminPro-BoldItalic.7b675fd3.woff2") format("woff2"), url("../AcuminPro-BoldItalic.d97e732a.woff") format("woff"), url("../AcuminPro-BoldItalic.ffffa042.ttf") format("truetype"), url("../AcuminPro-BoldItalic.2fd7f9c7.svg#AcuminPro-BoldItalic") format("svg");
  font-display: swap;
  font-family: Acumin Pro;
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: Acumin Pro;
  src: url("../AcuminPro-Bold.5c6968bd.eot");
  src: url("../AcuminPro-Bold.5c6968bd.eot#iefix") format("embedded-opentype"), url("../AcuminPro-Bold.f5d7bc15.woff2") format("woff2"), url("../AcuminPro-Bold.3a0d4738.woff") format("woff"), url("../AcuminPro-Bold.612bfe33.ttf") format("truetype"), url("../AcuminPro-Bold.c5a4b24e.svg#AcuminPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Acumin Pro;
  src: url("../AcuminPro-Regular.20053c8c.eot");
  src: url("../AcuminPro-Regular.20053c8c.eot#iefix") format("embedded-opentype"), url("../AcuminPro-Regular.b35eb542.woff2") format("woff2"), url("../AcuminPro-Regular.a720a9b1.woff") format("woff"), url("../AcuminPro-Regular.a2136d5d.ttf") format("truetype"), url("../AcuminPro-Regular.652b4e7d.svg#AcuminPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Acumin Pro;
  src: url("../AcuminPro-Italic.68ec0ad0.eot");
  src: url("../AcuminPro-Italic.68ec0ad0.eot#iefix") format("embedded-opentype"), url("../AcuminPro-Italic.a5481c91.woff2") format("woff2"), url("../AcuminPro-Italic.99e7e112.woff") format("woff"), url("../AcuminPro-Italic.9f2cb79c.ttf") format("truetype"), url("../AcuminPro-Italic.6a562d2e.svg#AcuminPro-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

body {
  color: var(--color-primary);
  background: #2c2c2d;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.-z-10 {
  z-index: -10;
}

.m-2 {
  margin: .5rem;
}

.m-8 {
  margin: 2rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-lg {
  max-width: 32rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-4 {
  padding: 1rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.opacity-80 {
  opacity: .8;
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

html {
  scroll-behavior: smooth;
  font-family: Acumin Pro, Helvetica, Arial, Liberation Sans, sans-serif;
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-seconodary a:hover, .text-seconodary a:active, .text-seconodary button.link:hover, .text-seconodary button.link:active {
  color: var(--color-primary);
}

a:hover, a:active, button.link:hover, button.link:active {
  color: var(--color-secondary);
}

.whitespace-pre-line p {
  white-space: pre-line;
}

.home-video-modal {
  width: 100vw !important;
}

@media (width >= 768px) {
  .home-video-modal {
    width: 90vw !important;
    height: auto !important;
  }
}

.fade {
  transition: opacity .7s ease-in;
}

.fadeOut {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

.fadeIn.loading {
  opacity: 0;
}

.fadeIn.loaded {
  opacity: 1;
}

.Menu {
  --color-inactive: var(--color-primary);
  --color-background: #2c2c2d;
  --color-active: #fff;
  z-index: 1000;
}

@media (width <= 767px) {
  .Nav-Menu {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
    display: flex;
  }

  .Nav-Item {
    margin: 0 .1rem;
    font-size: .9rem;
  }

  .Nav-Item:after {
    content: " -";
  }

  .Nav-Item:last-child:after {
    content: "";
  }
}

@media (width >= 768px) {
  .Menu {
    margin-left: 2rem;
    position: fixed;
    top: 150px;
  }

  .Nav-Menu .Nav-Item {
    margin-bottom: 57px;
    transition: all .5s;
    position: relative;
  }

  .Nav-Menu .Nav-Item .Nav-Dot {
    border-style: solid;
    border-width: 2px;
    border-color: var(--color-primary-dark);
    pointer-events: none;
    z-index: 100;
    background-color: #0000;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    transition: all .5s;
    display: inline-block;
    position: absolute;
    top: 3px;
    scale: .5;
  }

  .Nav-Menu .Nav-Item:after {
    content: "";
    background-color: var(--color-primary-dark);
    z-index: 0;
    width: 1px;
    height: 72px;
    transition: all .5s;
    display: block;
    position: absolute;
    top: 17px;
    left: 8px;
  }

  .Nav-Menu .Nav-Item.active:after {
    height: 68px;
    top: 21px;
  }

  .Nav-Menu .Nav-Item:last-child:after {
    display: none;
  }

  .Nav-Menu .Nav-Item.active a {
    color: var(--color-active);
  }

  .Nav-Menu .Nav-Item.active .Nav-Dot {
    border-color: var(--color-active);
    scale: 1;
  }

  .Nav-Menu .Nav-Item a {
    color: var(--color-inactive);
    cursor: pointer;
    margin-left: 25px;
    text-decoration: none;
    transition: color 1s;
  }

  .Nav-Menu .Nav-Item a:hover, .Nav-Menu .Nav-Item a:active, .Nav-Menu .Nav-Item a:focus {
    color: var(--color-secondary);
  }

  .Nav-Indicator {
    z-index: 200;
    transition: top 1s;
    position: absolute;
  }

  .Nav-Indicator:after {
    border: 2px solid var(--color-active);
    content: "";
    background: none;
    border-radius: 18px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 3px;
    left: 3px;
  }
}

@media (width >= 640px) {
  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:columns-1 {
    columns: 1;
  }
}

@media (width >= 768px) {
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:ml-40 {
    margin-left: 10rem;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:inline {
    display: inline;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:columns-2 {
    columns: 2;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mt-48 {
    margin-top: 12rem;
  }

  .lg\:columns-4 {
    columns: 4;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 1280px) {
  .xl\:mt-48 {
    margin-top: 12rem;
  }

  .xl\:mt-64 {
    margin-top: 16rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:mt-72 {
    margin-top: 18rem;
  }
}
/*# sourceMappingURL=index.css.map */
