html {
  scroll-behavior: smooth;
  font-family: "Acumin Pro", Helvetica, Arial, "Liberation Sans", sans-serif;
}

@layer base {
  body {
    background: #2c2c2d;
    color: var(--color-primary);
  }
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-seconodary {
  a:hover,
  a:active,
  button.link:hover,
  button.link:active {
    color: var(--color-primary);
  }
}

a:hover,
a:active,
button.link:hover,
button.link:active {
  color: var(--color-secondary);
}

.whitespace-pre-line p {
  white-space: pre-line;
}

.home-video-modal {
  width: 100vw !important;
  @media (min-width: 768px) {
    width: 90vw !important;
    height: auto !important;
  }
}
