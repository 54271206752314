.Menu {
  --color-inactive: var(--color-primary);
  --color-background: #2c2c2d;
  --color-active: #ffffff;
  z-index: 1000;
}

@media (max-width: 767px) {
  .Nav-Menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .Nav-Item {
    margin: 0 0.1rem;
    font-size: 0.9rem;
    &:after {
      content: " -";
    }
    &:last-child:after {
      content: "";
    }
  }
}

@media (min-width: 768px) {
  .Menu {
    position: fixed;
    top: 150px;
    margin-left: 2rem;
  }

  .Nav-Menu {
    .Nav-Item {
      transition: all 0.5s ease;
      margin-bottom: 57px;
      position: relative;

      .Nav-Dot {
        transition: all 0.5s ease;
        scale: 0.5;
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        border-color: var(--color-primary-dark);
        background-color: transparent;
        pointer-events: none;
        position: absolute;
        top: 3px;
        z-index: 100;
      }

      // Menu left border
      &:after {
        transition: all 0.5s;
        content: "";
        position: absolute;
        width: 1px;
        height: 72px;
        background-color: var(--color-primary-dark);
        display: block;
        left: 8px;
        top: 17px;
        z-index: 0;
      }

      &.active:after {
        height: 68px;
        top: 21px;
      }

      &:last-child:after {
        display: none;
      }
      &.active {
        a {
          color: var(--color-active);
        }
        .Nav-Dot {
          border-color: var(--color-active);
          scale: 1;
        }
      }
      a {
        transition: color 1s ease 0s;
        color: var(--color-inactive);
        text-decoration: none;
        cursor: pointer;
        margin-left: 25px;
        &:hover,
        &:active,
        &:focus {
          color: var(--color-secondary);
        }
      }
    }
  }

  .Nav-Indicator {
    position: absolute;
    transition: top 1s ease 0s;
    z-index: 200;
    &:after {
      border: 2px solid var(--color-active);
      background: transparent;
      position: absolute;
      content: "";
      border-radius: 18px;
      width: 12px;
      height: 12px;
      top: 3px;
      left: 3px;
    }
  }
}
